import React from "react";
import SectionHeader from "../Shared/SectionHeader";
import Project from "../../Models/Projects/projects";
import ProjectRow from "./ProjectRow";

import app from "../../Images/Projects/app.webp";
import dashboard from "../../Images/Projects/dashboard.webp";
import koelkast from "../../Images/Projects/koelkast.webp";

export default function Projects() {
	const projects: Project[] = [
		{
			title: "MC22L - iOS APP",
			description: `
				Zelfontwikkelde iOS-app voor mijn vriendengroep met diverse functionaliteiten, waaronder het bedienen\
				van IoT-apparaten, bijhouden van dartscores en het plaatsen van consumptie-bestellingen. Deze bestellingen \
				worden gevalideerd met behulp van Artificial Intelligence (AI). App is beschikbaar via Apple AppStore.
			`,
			skills: ["SWIFT", "ASP.NET Core", "YOLOV5 AI", "Azure DevOps", "CI/CD"],
			image: app,
		},
		{
			title: "MC22L - FULL-STACK / DASHBOARD",
			description: `
				Webapplicatie die real-time data visualiseert op een TV-scherm, waaronder dartscores, \
				live sportnieuws, voorraadniveaus en aantal consumpties per gebruiker.

				Het dashboard communiceert via API en Websockets met de backend om de laatste data te visualiseren.
			`,
			skills: [
				"HTML5 / Sass / TypeScript",
				"ASP.NET Core",
				"NUnit / Selenium",
				"Azure DevOps",
				"CI/CD",
			],
			image: dashboard,
		},
		{
			title: "MC22L - KOELKAST",
			description: `
				Multifunctionele full-stack applicatie, geïntegreerd in een koelkast en draaiend op een Raspberry Pi. \
				Gebruikers kunnen via een scherm consumptie-bestellingen plaatsen en producten uit de koelkast pakken.
				De applicatie beschikt over een interne camera die, met behulp van Artificial Intelligence (AI), automatisch \
				de voorraad detecteerd/bijhoudt. Verder beschikt de applicatie over een sensor om te alarmeren wanneer de \
				koelkastdeur wordt geopend zonder het plaatsen van een bestelling.
			`,
			skills: [
				"React",
				"Electron",
				"Raspberry Pi / IoT",
				"YOLO AI",
				"Azure DevOps",
				"CI/CD",
			],
			image: koelkast,
		},
	];
	return (
		<section className="projects">
			<SectionHeader title="Projecten" />

			<div className="projects-wrapper">
				{projects.map((project, i) => {
					return <ProjectRow key={i} project={project} />;
				})}
			</div>

			<p className="more"> En nog vele andere projecten... </p>
		</section>
	);
}
