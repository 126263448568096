import React from "react";
import Skill from "../../Models/Skills/skills";
import SkillCard from "./SkillCard";
import SectionHeader from "../Shared/SectionHeader";

import html5 from "../../Images/Skills/html5.webp";
import swift from "../../Images/Skills/swift.webp";
import csharp from "../../Images/Skills/csharp.webp";
import react from "../../Images/Skills/react.webp";
import electron from "../../Images/Skills/electron.webp";
import sql from "../../Images/Skills/sql.webp";

export default function Skills() {
	const skills: Skill[] = [
		{ title: "HTML5 / Sass / TS", image: html5, percentage: 95 },
		{ title: "SWIFT / SWIFTUI", image: swift, percentage: 90 },
		{ title: "C# / ASP .NET", image: csharp, percentage: 90 },
		{ title: "REACT", image: react, percentage: 80 },
		{ title: "SQL", image: sql, percentage: 80 },
		{ title: "ELECTRON", image: electron, percentage: 80 },
	];

	return (
		<section className="skills">
			<SectionHeader title="Vaardigheden" />

			<div className="skills-wrapper">
				{skills.map((skill, i) => {
					return <SkillCard key={i} skill={skill} index={i} />;
				})}
			</div>
		</section>
	);
}
