import React, { useRef } from "react";

import TypeWriter from "typewriter-effect";
import Logo from "../Shared/Logo";
import Socials from "../Shared/Socials";
import SectionHeader from "../Shared/SectionHeader";
import useIsInViewport from "../../Hooks/useIsInViewPort";

export default function Home() {
	const subtitleRef = useRef(null);

	return (
		<section className="home">
			<div className="title-container">
				<Logo />

				<h1 className="title">
					Bink <br />
					de Wildt
				</h1>

				<Socials />
			</div>

			<section className="introduction">
				<SectionHeader title="Wie ben ik?" />

				<h2
					className={`subtitle ${
						!useIsInViewport(subtitleRef) ? "nowrap" : ""
					}`}
					ref={subtitleRef}
				>
					Ik ben een{" "}
					<TypeWriter
						options={{
							loop: true,
							autoStart: true,
							strings: [
								"web developer",
								"frontend developer",
								"backend developer",
								"full-stack developer",
							],
							delay: 100,
							deleteSpeed: 70,
						}}
					/>
				</h2>

				<p>
					Als recent afgestudeerde HBO Informatica-specialist, richting
					'Interactie Technologie', ben ik gedreven om innovatieve en efficiënte
					softwareoplossingen te ontwikkelen. Met ervaring in diverse projecten,
					zowel persoonlijk als voor verschillende opdrachtgevers, heb ik een
					brede set aan IT-vaardigheden opgebouwd.
					<br />
					Mijn passie ligt bij het toepassen van technologie in de praktijk,
					waarbij ik software combineer met complexe uitdagingen. Ik ben ook
					geïnteresseerd in de toepassing van IT binnen de financiële sector en
					hoe technologie processen kan optimaliseren. Ontdek hier mijn werk en
					mijn uitgevoerde projecten!
					<br />
					<br />
					Neem contact op voor samenwerkingen of een diepgaande blik op mijn
					vaardigheden en expertise in softwareontwikkeling, webdesign en meer.
				</p>
			</section>
		</section>
	);
}
