import React from "react";
import SectionHeader from "../Shared/SectionHeader";
import Education from "../../Models/Education/education";
import TimelineRow from "../Shared/TimlineRow";

export default function Educations() {
	const educations: Education[] = [
		{
			period: "2020 - 2024",
			title: "HOGESCHOOL LEIDEN",
			subtitle: "BACHELOR - INFORMATICA",
			text: `
				Specialisatie in 'Interactie Technologie'. Gespecialiseerd in gebruikersinteractie en mens-machine communicatie (front-end/full-stack & IoT). \
				Gericht op de 'Design Thinking'-methodiek en UX design principes om gebruikerservaringen te optimaliseren.
				
				In de praktijk toegepast door software te ontwikkelen voor een online Escape room, VR-applicatie, IoT-systeem met front- & backend, E-Learning \
				platform voor PostNL én een project voor NPO / NTR met de nadruk op toegankelijkheid.`,
		},
		{
			period: "2023",
			title: "Hogeschool van Amsterdam",
			subtitle: "MINOR - BELEGGEN EN DUURZAAMHEID",
			text: ``,
		},
		{
			period: "2015 - 2020",
			title: "RIJNLANDS LYCEUM SASSENHEIM",
			subtitle: "HAVO - NATUUR EN TECHNIEK",
		},
	];
	return (
		<section className="educations">
			<SectionHeader title="Opleidingen" />

			<div className="timeline educations-wrapper">
				{educations.map((education, i) => {
					return <TimelineRow key={i} row={education} />;
				})}
			</div>
		</section>
	);
}
